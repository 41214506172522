<template>
  <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29 0C13.0089 0 0 13.0089 0 29C0 44.9911 13.0089 58 29 58C44.9911 58 58 44.9911 58 29C58 13.0089 44.9911 0 29 0ZM29 55.5834C14.3429 55.5834 2.41663 43.6571 2.41663 29C2.41663 14.3429 14.3429 2.41663 29 2.41663C43.6571 2.41663 55.5834 14.3429 55.5834 29C55.5834 43.6571 43.6571 55.5834 29 55.5834Z"
      fill="#00B6F8"/>
    <path
      d="M18.125 16.9166C16.1264 16.9166 14.5 18.543 14.5 20.5416C14.5 22.5402 16.1264 24.1666 18.125 24.1666C20.1236 24.1666 21.75 22.5402 21.75 20.5416C21.75 18.5431 20.1236 16.9166 18.125 16.9166ZM18.125 21.75C17.458 21.75 16.9166 21.2086 16.9166 20.5416C16.9166 19.8746 17.458 19.3333 18.125 19.3333C18.792 19.3333 19.3334 19.8746 19.3334 20.5416C19.3334 21.2086 18.792 21.75 18.125 21.75Z"
      fill="#00B6F8"/>
    <path
      d="M39.875 16.9166C37.8764 16.9166 36.25 18.543 36.25 20.5416C36.25 22.5402 37.8764 24.1666 39.875 24.1666C41.8736 24.1666 43.5 22.5402 43.5 20.5416C43.5 18.5431 41.8736 16.9166 39.875 16.9166ZM39.875 21.75C39.208 21.75 38.6666 21.2086 38.6666 20.5416C38.6666 19.8746 39.208 19.3333 39.875 19.3333C40.542 19.3333 41.0834 19.8746 41.0834 20.5416C41.0834 21.2086 40.542 21.75 39.875 21.75Z"
      fill="#00B6F8"/>
    <path
      d="M15.1222 45.7242C15.6853 46.0867 16.432 45.9199 16.792 45.3593C19.4818 41.1687 24.0445 38.6651 28.9987 38.6651C33.9528 38.6651 38.5155 41.1687 41.2053 45.3592C41.5654 45.9223 42.3169 46.0866 42.8752 45.7241C43.4383 45.364 43.6002 44.6148 43.24 44.0542C40.1057 39.1677 34.7817 36.2483 28.9987 36.2483C23.2156 36.2483 17.8917 39.1677 14.7573 44.0542C14.3972 44.6173 14.5615 45.3641 15.1222 45.7242Z"
      fill="#00B6F8"/>
  </svg>
</template>
<script>
import Vue from 'vue';
const IconSadSmiley = Vue.extend({
  name: 'icon-sad-smiley',
});
export default IconSadSmiley;
</script>
